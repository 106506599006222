import {
  Box,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { styles } from "./index.style.module";
import { FilterBar } from "./components/filterBar/FilterBar";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "./slice";
import { myApprovalSaga } from "./saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import { useEffect } from "react";
import { Pagination, Table } from "@agnext/reactlib";
import { pendingApprovalHeaders } from "views/Variables/variables";
import { ActionIcon } from "components/Icons/Icons";
import { useWarehouseFilter } from "../../../Dashboard/context/WarehouseFilterContext";
import { useReviewAddFilter } from "views/Users/pages/myApprovals/context/ReviewAddFilterContext";
import { ActionBtnDropdown } from "./components/actionBtnDropdown/ActionBtnDropdown";
import { format } from "date-fns";
import { calculateTotalPages } from "utils/commonFunctions";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { Link } from "react-router-dom";
import { ViewReportIcon } from "components/Icons/Icons";
import reviewSvg from "assets/svg/review.svg";
import { extractAuditorNames } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";
const PendingApproval = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myApprovalSaga });

  const [submittedPage, setSubmittedPage] = useState(0);
  const totalPendingApprovalsCount = useSelector(selectors.selectTotalPendingApprovalsCount)
  // const { selectedWarehouse } = useWarehouseFilter();
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const { query } = useReviewAddFilter();
  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });

  const { fromDate, toDate } = filters;

  const handleFromDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };

  let warehouses = useSelector(selectors.selectWarehouseData) || [];

  let dispatch = useDispatch();

  useEffect(() => {
    const requiredWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouse
    );
    const warehouseId = selectedWarehouse ? requiredWarehouse?.id : null;
    const forQueryParams = {
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
    };
    const submittedAnalyticsDataParams = {
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
      status: ["done", "review-inprogress"],
      page: submittedPage,
    };
    
    dispatch(
      actions.fetchSubmittedInspectionData({
        payload: submittedAnalyticsDataParams,
      })
    );
  
    dispatch(
      actions.fetchWarehouses({
        payload: forQueryParams,
      })
    );
  }, [
    // clientName,
    // location,
    selectedWarehouse,
    fromDate,
    toDate,
    submittedPage,
    query,
  ]);

  const submittedArray = useSelector(selectors.selectSubmittedInspectionData);
  const isSignEnabled = useSelector(selectors.selectDisplayStatus);

  const totalPages = calculateTotalPages(
    totalPendingApprovalsCount,
    10
  );

  const handlePageSelect = (pageNumber) => {
    setSubmittedPage(pageNumber);
  };
  const handleNextPage = () => {
    if (submittedPage === totalPages - 1) return;
    setSubmittedPage(submittedPage + 1);
  };

  const handlePreviousPage = () => {
    if (submittedPage === 0) return;
    setSubmittedPage(submittedPage - 1);
  };

  let headerArray = pendingApprovalHeaders;
  let headerArray2 = pendingApprovalHeaders.filter(item => item.displayText != 'Status');

  let tableData =
    submittedArray &&
    submittedArray.map((inspection, i) => {
      const {
        auditDate,
        originName,
        clientOrgName,
        originLocation,
        signEnabled,
        auditors,
        isPending,
        isExpired,
      } = inspection;

      // Updating inspectionDate and removing the time
      const updatedAuditDate = format(new Date(auditDate), "yyyy-MM-dd");
      const updateOriginName = capitalizeStringUpdated(originName);
      const updatedOriginLocation = capitalizeStringUpdated(originLocation);
      const updatedOrgName = capitalizeStringUpdated(clientOrgName);
      const updatedAuditorName = extractAuditorNames(auditors);
      const updatedSignEnabled = (signEnabled?.toString() == 'true')? (!isPending)?'Review Pending':(isExpired)? 'Link Expired':'Signature Pending':'-';

      return {
        ...inspection,
        auditDate: updatedAuditDate,
        originName: updateOriginName,
        orgName: updatedOrgName,
        auditorName: updatedAuditorName,
        originLocation: updatedOriginLocation,
        signEnabled: updatedSignEnabled,
        action: (
          <Tooltip label="Review" alignSelf={"center"}>
            <Link
              to={`/admin/pendingApprovals/${inspection?.id}`}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={reviewSvg} />
            </Link>
          </Tooltip>
        ),
      };
    });

  const handleApplyFilter = () => {
    setSubmittedPage(0);
  };

  return (
    <>
      <Box mt={"75px"} display={"flex"} flexDirection={"column"} gap={"36px"}>
        <Text
          sx={styles.pendingApprovals}
        >{`${totalPendingApprovalsCount} Pending Approval`}</Text>
        <ErrorBoundary
          fallback={<FallbackToast message={`Failed to Load filters`} />}
        >
          <FilterBar
            selectedWarehouse={selectedWarehouse}
            setSelectedWarehouse={setSelectedWarehouse}
            warehouses={warehouses}
            filters={filters}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
            handleApplyFilter={handleApplyFilter}
          />
        </ErrorBoundary>
        <ErrorBoundary fallback={<FallbackUI minH="70vh" />}>
          <Box sx={styles.container}>
            {isSignEnabled?  <Table 
              headerList={headerArray}
              dataList={tableData}
              overflow="auto"
              stickyColumn={["Actions"]}
            />:
            <Table 
            headerList={headerArray2}
            dataList={tableData}
            overflow="auto"
            stickyColumn={["Actions"]}
          />
            }
           
          </Box>
        </ErrorBoundary>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} w={"100%"} mt={"10px"}>
        <ErrorBoundary
          fallback={<FallbackToast message={`Failed to Load Pagination`} />}
        >
          <Pagination
            page={submittedPage}
            numPages={totalPages}
            handlePageSelect={handlePageSelect}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            colorActive="#4FD1C5"
          />
        </ErrorBoundary>
      </Box>
    </>
  );
};

export const Inspections = () => {
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <PendingApproval />
    </ErrorBoundary>
  );
};

import React from "react";
import "index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import MobileAuthLayout from "layouts/MobileAuth";
import CommonLayout from "layouts/Common";
import { configureAppStore } from "./store/configureStore";
import { SidebarProvider } from "./context/SidebarContext";
import { WarehouseFilterProvider } from "./views/Dashboard/context/WarehouseFilterContext";
import { AddFilterProvider } from "./views/Dashboard/context/AddFilterContext";
import { userStorage } from "utils/helper";
import { ReviewAddFilterProvider } from "views/Users/pages/myApprovals/context/ReviewAddFilterContext";
import { UserContextProvider } from "context/UserContext";
import { useUserContext } from "context/UserContext";
import { ErrorBoundary } from "react-error-boundary";
import TopLevelFallback from "errorsFallback/TopLevelFallback";
import NetworkStatus from "components/NetworkStatus/NetworkStatus";

const App = () => {
  const store = configureAppStore();
  const userRoles = localStorage.getItem("roleName");
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<TopLevelFallback />}>
        <Provider store={store}>
            <SidebarProvider>
              <AddFilterProvider>
                <ReviewAddFilterProvider>
                  <WarehouseFilterProvider>
                    <UserContextProvider>
                      <Switch>
                        <Route path={`/auth`} component={AuthLayout} />
                        <Route path={`/admin`} component={AdminLayout} />
                        <Route
                          path={`/privacy-policy`}
                          component={CommonLayout}
                        />
                        <Route
                          path={`/mobile/auth`}
                          component={MobileAuthLayout}
                        />
                        <Redirect
                          from={`*`}
                          to={
                            
                             "/admin/dashboard"
                          }
                        />
                      </Switch>
                    </UserContextProvider>
                  </WarehouseFilterProvider>
                </ReviewAddFilterProvider>
              </AddFilterProvider>
            </SidebarProvider>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(<App />);

import React, { useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import { ChevronDownIcon as CollapseIcon } from "@chakra-ui/icons";
import { MdArrowForwardIos } from "react-icons/md";
import Components from "../components";
// lazy imports
// const Components = React.lazy(() => import("../components/index.jsx"));

export default function Section({
  section,
  data,
  formData,
  handleFormDataChange,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  formErrorData,
  tempError,
  prevPages,
  setSkippedPages,
  page,
  index,
  handleBlur,
  handleToggleSection,
  expandedSections,
  // isOnline
}) {
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");

  const [isSectionHidden, setIsSectionHidden] = useState(() => {
    if (expandedSections.length && expandedSections.includes(section.id)) return false;
    return true;
  });

  useEffect(() => {
    if (expandedSections.length && expandedSections.includes(section.id)) {
      setIsSectionHidden(false)
    } else {
      setIsSectionHidden(true);
    }
  }, [expandedSections])

  const hasInvalidElementInSection = ()=>{
    const elementsId = data?.flatMap((block) =>
      block.component?.structure?.elements.map((element) => element.id)
    );

    const hasInvalidElement = elementsId?.some((id) => {
      return formErrorData[id]?.isValid === false;
    });

    return hasInvalidElement;
  }

  return (
    <VStack w="100%" aria-label="section" gap="20px">
      <HStack
        style={{
          background: "#EDF2F7",
          border: hasInvalidElementInSection() ? "1.5px solid #F44336" : "",
          borderRadius: "8px",
          padding: "8px 16px 8px 8px",
          width: "100%",
        }}
        justify="space-between"
        align="flex-start"
      >
        <HStack justify="center" align="center">
          <IconButton
            variant="ghost"
            borderRadius="8px"
            // isDisabled={index < 1}
            sx={{
              ".icon": {
                color: "#718096",
              },
            }}
            icon={
              isSectionHidden ? (
                <MdArrowForwardIos w="26px" h="26px" className="icon" />
              ) : (
                <CollapseIcon w="26px" h="26px" className="icon" />
              )
            }
            onClick={() =>
              handleToggleSection(
                section.id,
                isSectionHidden ? "expand" : "hide"
              )
            }
          />
          <HStack align="center">
            <Text
              color="#4A5568"
              fontSize={isMobileScreen ? "16px" : "22px"}
              fontWeight="600"
              lineHeight="1.4"
            >
              {section.title}
            </Text>
            <Text
              color="#718096"
              fontSize={isMobileScreen ? "10px" : "12px"}
              fontWeight="400"
              lineHeight="1.4"
            >
              {section.description}
            </Text>
          </HStack>
        </HStack>
        {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
      </HStack>
      {isSectionHidden ? (
        <></>
      ) : (
        <Components
          data={data}
          isSectionHidden={isSectionHidden}
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          displayData={displayData}
          setPage={setPage}
          navIndex={navIndex}
          setNavIndex={setNavIndex}
          formErrorData={formErrorData}
          prevPages={prevPages}
          setSkippedPages={setSkippedPages}
          page={page}
          handleBlur={handleBlur}
        />
      )}
    </VStack>
  );
}

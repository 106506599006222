import { HStack, Box, ButtonGroup } from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import Editable from "../../../Components/Editable";
import * as selectors from "../selectors";
import { actions } from "../slice";
import { Button } from "@agnext/reactlib";
import { AddIcon } from "@chakra-ui/icons";
import Component from "../Component";
import { generateId } from "../../../../../utils/index";
import LinkTo from "../../../Components/LinkTo";

export default function Composite({
  compositeId,
  blockId,
  compositeOrder,
  compositeOrderLast,
  sectionId,
}) {
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [title, setTitle] = useState({
    label: "",
    value: "",
  });

  const [compositeDescription, setCompositeDescription] = useState(
    "Description"
  );

  const composites = useSelector(selectors.selectCompositesForComponents);

  const dispatch = useDispatch();

  const getComponents = useCallback(() => {
    let components = [];
    if (composites.length) {
      composites.forEach((composite) => {
        if (composite.id === compositeId) {
          components = composite.structure && composite.structure.components;
        }
      });
    }
    return components;
  }, [composites, compositeId]);

  const getCompositeName = useCallback(() => {
    let compositeName = "";
    if (composites.length) {
      composites.forEach((composite) => {
        if (composite.id === compositeId) {
          compositeName = composite.title;
        }
      });
    }
    return compositeName;
  }, [composites, compositeId]);

  const [compositeName, setcompositeName] = useState(getCompositeName());

  const getLinks = useCallback(() => {
    let links;
    if (composites.length) {
      composites.forEach((composite) => {
        if (composite.id === compositeId) {
          links = composite.structure &&
            composite.structure.links && [...composite.structure.links];
        }
      });
    }
    return links || [];
  }, [compositeId, composites]);

  const handleCompositeNameChange = (value) => {
    setcompositeName(value);
  };

  const handleCompositeDescriptionChange = (value) => {
    setCompositeDescription(value);
  };

  const handleSubmitLinkTo = () => {
    dispatch(
      actions.addLinkToComposite({
        compositeId,
        linkTo: {
          type: selectedOption.value,
          id: title.value,
          title: title.label,
        },
      })
    );
    const newOption = { label: "", value: "" };
    setSelectedOption(newOption);
    setTitle(newOption);
  };

  const handleCompositeDelete = () => {
    const payload = {
      blockId,
      compositeId,
    };
    dispatch(actions.deletionComposite(payload));
  };

  const handleCompositeDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      blockId,
      compositeId,
    };
    dispatch(actions.duplicateComposite(payload));
  };

  const handleCompositeNameSubmit = (compositeName) => {
    const payload = {
      compositeId,
      blockId,
      compositeName,
    };
    dispatch(actions.editCompositeName(payload));
  };

  const handleCompositeDescriptionSubmit = (compositeDescription) => {
    const payload = {
      compositeId,
      compositeDescription,
    };
    dispatch(actions.editCompositeDescription(payload));
  };

  const handleAddComponent = async () => {
    const componentId = await generateId();
    dispatch(actions.initNewComponent({ compositeId, componentId }));
  };

  const moveUpHandlerComposite = () => {
    const payload = {
      blockId,
      compositeId,
    };
    dispatch(actions.moveUpComposite(payload));
  };

  const moveDownHandlerComposite = () => {
    const payload = {
      blockId,
      compositeId,
    };
    dispatch(actions.moveDownComposite(payload));
  };

  let components = getComponents();
  return (
    <>
      <Box
        w={"100%"}
        style={{
          padding: "16px",
          border: "1px solid #EFEFEF",
          margin: "16px 0 16px 0",
          borderLeft: "2px solid rgba(255, 191, 0, 0.5)",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <HStack
          justify="space-between"
          style={{
            background: "rgba(84,3, 117, 0.03)",
            padding: "7px 12px",
            borderRadius: "0 8px 8px 0",
          }}
        >
          <Editable
            text={compositeName}
            handleTextChange={handleCompositeNameChange}
            handleSubmit={handleCompositeNameSubmit}
          />
          <ButtonGroup
            justifyContent="center"
            size="md"
            style={{ position: "relative" }}
          >
            <Moveup
              moveUpHandler={moveUpHandlerComposite}
              isDisabled={compositeOrder === 1 ? true : false}
            />
            <MoveDown
              moveDownHandler={moveDownHandlerComposite}
              isDisabled={compositeOrder === compositeOrderLast ? true : false}
            />
            <Delete deleteHandler={handleCompositeDelete} />
            <Duplicate duplicateHandler={handleCompositeDuplicate} />
            <LinkTo
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              title={title}
              setTitle={setTitle}
              handleSubmit={handleSubmitLinkTo}
              links={getLinks()}
              parentType="composites"
              parentId={compositeId}
            />
          </ButtonGroup>

          <Box
            w={"40%"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={handleAddComponent}
              size="sm"
              colorScheme="login"
              leftIcon={<AddIcon />}
              label="Component"
            />
          </Box>
        </HStack>
        <Box w="40%" style={{ paddingLeft: "12px" }}>
          <Editable
            text={compositeDescription}
            handleTextChange={handleCompositeDescriptionChange}
            handleSubmit={handleCompositeDescriptionSubmit}
          />
        </Box>
        {getComponents() &&
          getComponents().map((component) => (
            <Component
              key={component.id}
              componentId={component.id}
              compositeId={compositeId}
              sectionId={sectionId}
              componentOrder={component.order}
              componentOrderLast={
                composites.length >= 1
                  ? components[components.length - 1].order
                  : 0
              }
            />
          ))}
      </Box>
    </>
  );
}

// import
import SignIn from "views/Auth/SignIn.js";
import activeDashboardSVG from "assets/svg/MdDashboard.svg";
import dashboardSVG from "assets/svg/MdDashboard-teal.svg";
import activeInspectionSVG from "assets/svg/HiDocumentSearch.svg";
import inspectionSVG from "assets/svg/HiDocumentSearch-teal.svg";
import activeApprovalSVG from "assets/svg/MdOutlineApproval.svg";
import approvalSVG from "assets/svg/MdOutlineApproval-teal.svg";

import {
  HomeIcon,
  StatsIcon,
  // CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  // SupportIcon,
} from "components/Icons/Icons";

import Dashboard from "views/Dashboard/Inspection Table";
import InspectionDetail from "views/Dashboard/pages/inspectionDetail";
import Notifications from "views/Notification";
import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
import SendOTP from "views/Auth/SendOTP/SendOTP";
import SetNewPassword from "views/Auth/SetNewPassword/SetNewPassword";
import PasswordResetSuccessful from "views/Auth/PasswordResetSuccessful/PasswordResetSuccessful";
import Account from "views/Accounts";
import AnalyticsDashboard from "views/Dashboard/Analytics Dashboard";
import GenerateDashboard from "views/Dashboard/pages/generateDashboard";
import GenerateWorkflow from "views/Workflow/pages/generateWorkflow";
import { WorkflowSubmitted } from "views/Workflow/pages/generateTemplate/WorkflowSubmitted";
import { MobileWorkflowSubmitted } from "views/Workflow/pages/generateTemplate/WorkflowSubmitted";
import GenerateMobileWorkflow from "views/Workflow/pages/generateMobileWorkflow";
import { Inspections } from "views/Users/pages/myApprovals";
import Warehouses from "views/Dashboard/Warehouses/pages/Warehouses/Warehouses";
import CmTable from "views/Dashboard/Collateral Manager/Pages/Collateral Manager Table/CmTable";
import CreateRole from "views/RoleAndPermission/CreateRole";
import EditRole from "views/RoleAndPermission/EditRole";
import ViewRole from "views/RoleAndPermission/ViewRole";
import UserRole from "views/RoleAndPermission/UserRole";
import Create from "views/Workflow Builder/pages/Create";
import Update from "views/Workflow Builder/pages/Update";
import Workflows from "views/Workflow Builder/pages/Workflows";
import View from "views/Workflow Builder/pages/View";
import SummaryNCCF from "views/Dashboard/SummaryNCCF";
import Customers from "views/Customer/Pages/Customers";
import ViewCustomer from "views/Customer/Pages/ViewCustomer";
import AddCustomer from "views/Customer/Pages/AddCustomer";
import Client from "views/Client/Pages/Client";
import ViewClient from "views/Client/Pages/ViewClient";
import AddClient from "views/Client/Pages/AddClient";
import User from "views/User/Pages/User";
import AddUser from "views/User/Pages/AddUser";
import ViewUser from "views/User/Pages/ViewUser";
import AddJob from "views/jobs/pages/AddJob";
import AllJobs from "views/jobs/pages/AllJobs";
import ViewJob from "views/jobs/pages/ViewJob";
import EditJob from "views/jobs/pages/EditJob";
import ViewResponses from "views/jobs/components/viewJob/ViewResponses";
import WorkflowManager from "views/Workflow Builder/pages/WorkflowManager";
import ReviewInspection from "views/Users/pages/reviewInspection/ReviewInspection";
import EditCustomer from "views/Customer/Pages/EditCustomer";
import EditClient from "views/Client/Pages/EditClient";
import EditUser from "views/User/Pages/EditUser";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";
import Move from "views/Workflow Builder/pages/Move";

var dashRoutes = [
  // {
  //   path: "/dashboard/summary",
  //   name: "Summary",
  //   component: SummaryNCCF,
  //   layout: "/admin",
  //   activeIcon: activeDashboardSVG,
  //   inActiveIcon: dashboardSVG,
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: GenerateDashboard,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/inspection-menu",
    name: "Inspection Detail",
    component: InspectionDetail,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
  },
  {
    path: "/inspections",
    name: "Inspections",
    component: Dashboard,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: false,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: <HomeIcon color="inherit" />,
    component: Notifications,
    layout: "/admin",
    isBackBtn: false,
  },
  {
    path: "/account",
    name: "Account",
    icon: <HomeIcon color="inherit" />,
    component: Account,
    layout: "/admin",
    isBackBtn: false,
  },
  {
    path: "/viewForm",
    name: "Bank Audit",
    component: GenerateWorkflow,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/formSubmitted",
    name: "Bank Audit ",
    component: WorkflowSubmitted,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/form-view/:id",
    name: "Mobile Form View",
    component: GenerateMobileWorkflow,
    layout: "/mobile/auth",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/form-submitted/:id",
    name: "Bank Audit",
    component: MobileWorkflowSubmitted,
    layout: "/mobile/auth",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/collateralManagers",
    name: "Collateral Managers",
    component: CmTable,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/pendingApprovals/:inspectionid",
    name: "Review Form",
    component: ReviewInspection,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: true,
  },
  {
    path: "/pendingApprovals",
    name: "Pending Approvals",
    component: Inspections,
    layout: "/admin",
    activeIcon: activeApprovalSVG,
    inActiveIcon: approvalSVG,
    isBackBtn: false,
  },

  {
    path: "/userRoles/create",
    name: "Create Role",
    component: CreateRole,
    layout: "/admin",
    isBackBtn: true,
  },
  {
    path: "/userRoles/edit/:roleId",
    name: "Edit Role",
    component: EditRole,
    layout: "/admin",
    isBackBtn: true,
  },
  {
    path: "/userRoles/view/:roleId",
    name: "View Role",
    component: ViewRole,
    layout: "/admin",
    isBackBtn: true,
  },
  {
    path: "/userRoles",
    name: "User Roles",
    component: UserRole,
    layout: "/admin",
    isBackBtn: false,
  },
  {
    path: "/workflows/:workflowId/:formId/create",
    name: "Create Form",
    component: Update,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: true,
  },
  {
    path: "/workflows/:workflowId/:formId/move",
    name: "Move Form",
    component: Move,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: true,
  },
  {
    path: "/workflows/:workflowId/:formId/view",
    name: "View Workflow",
    component: View,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: true,
  },
  {
    path: "/workflows/:workflowId",
    name: "Update Workflow",
    component: WorkflowManager,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: true,
  },
  {
    path: "/workflows",
    name: "Workflows",
    component: Workflows,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
    isBackBtn: false,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: SignIn,
    layout: "/auth",
    isBackBtn: false,
  },
  {
    path: "/forgotPassword",
    name: "Forgot Password",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: ForgotPassword,
    layout: "/auth",
    isBackBtn: false,
  },
  {
    path: "/sendOtp",
    name: "Send OTP",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SendOTP,
    layout: "/auth",
    isBackBtn: false,
  },
  {
    path: "/newPassword",
    name: "New Password",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SetNewPassword,
    layout: "/auth",
    isBackBtn: false,
  },
  {
    path: "/passwordResetSuccessful",
    name: "Password Reset Successful",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: PasswordResetSuccessful,
    layout: "/auth",
    isBackBtn: false,
  },
  {
    path: "/add-job",
    name: "Create Job",
    component: AddJob,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  // {
  //   path: "/edit-job",
  //   name: "Update Job",
  //   component: EditJob,
  //   layout: "/admin",
  //   activeIcon: activeInspectionSVG,
  //   inActiveIcon: inspectionSVG,
  // },
  {
    path: "/edit-job/:inspectionId/:jobId",
    name: "Update Job",
    component: EditJob,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: AllJobs,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: false,
  },
  {
    path: "/viewjob/:inspectionid/:jobid",
    name: "View Job",
    component: ViewJob,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/viewresponses",
    name: "View Responses",
    component: ViewResponses,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/viewinspection",
    name: "View Inspection",
    component: ViewResponses,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  // {
  //   path: "/signup",
  //   name: "Sign Up",
  // rtlName: "لوحة القيادة",
  //   icon: <RocketIcon color="inherit" />,
  //   secondaryNavbar: true,
  //   component: SignUp,
  //   layout: "/auth",
  // },
  //   ],
  // },
  {
    path: "/agency/updateAgency",
    name: "Edit Agency",
    component: EditCustomer,
    layout: "/admin",
    isBackBtn: true,
  },
  {
    path: "/agency/addAgency",
    name: "Add Agency",
    component: AddCustomer,
    layout: "/admin",
    isBackBtn: true,
  },

  {
    path: "/agency/viewAgency",
    name: "View Agency",
    component: ViewCustomer,
    layout: "/admin",
    isBackBtn: true,
  },
  {
    path: "/agency",
    name: "Agency",
    component: Customers,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: false,
  },
  {
    path: "/clients/addClient",
    name: "Add Client",
    component: AddClient,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/clients/updateClient",
    name: "Edit Client",
    component: EditClient,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/clients/viewClient",
    name: "View Client",
    component: ViewClient,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Client,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
  },
  {
    path: "/clients/editClient",
    name: "Edit Client",
    component: EditClient,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/users/viewUser",
    name: "View User",
    component: ViewUser,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/users/updateUser",
    name: "Update User",
    component: EditUser,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/users/addUser",
    name: "Add User",
    component: AddUser,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: true,
  },
  {
    path: "/users",
    name: "Users",
    component: User,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: false,
  },
  {
    path: "privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    layout: "/",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
    isBackBtn: false,
  },
];
export default dashRoutes;

import React, { useEffect, useRef} from "react";
import { Stack, Box, Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

export default function MultiSelect({
  data,
  handleFormDataChange,
  formData,
  repeatable,
  index,
  handleBlur,
  handleValidation
}) {
  if (repeatable?.isRepeatable) {
    const isCheckboxFocused = useRef(false); //this is used to prevent blur count to be increased in case we  click on different radio options for the same question
    
    const repeatIndex = index;
    const values = formData[data.element.id].attachments[repeatIndex]
      ? JSON.parse(formData[data.element.id].attachments[repeatIndex])
      : [];

    const handleChange = (val) => {
      handleFormDataChange(
        JSON.stringify(val),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    };

    useEffect(() => {
      handleFormDataChange(
        JSON.stringify(values),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    }, []);

    return (
      <Stack
        onBlur={() => {
          setTimeout(() => {
            if (!isCheckboxFocused.current) {
              handleBlur();
            }
          },100)
        }}
        onFocus={() => (isCheckboxFocused.current = true)}
      >
        {formData[data.element.id] ? (
          <CheckboxGroup
            name={`${data.element.id}${repeatIndex}`}
            value={values}
            onChange={(val) => {
              handleChange(val);
              handleValidation(data.element.id, repeatIndex)
            }}
          >
            {data &&
            data.element &&
            data.element.properties &&
            data.element.properties.options ? (
              data.element.properties.options.map((option) => {
                const capitalizedOption = capitalizeStringUpdated(option.label);
                return (
                  <Box
                    style={{
                      border: "1px solid #EDF2F7",
                      borderRadius: "8px",
                      padding: "12px",
                    }}
                    key={option.value}
                    onFocus={() => (isCheckboxFocused.current = true)}
                    onBlur={() => (isCheckboxFocused.current = false)}
                  >
                    <Checkbox
                      size="md"
                      colorScheme="login"
                      value={option.value}
                    >
                      {capitalizedOption}
                    </Checkbox>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </CheckboxGroup>
        ) : (
          <></>
        )}
      </Stack>
    );
  }

  // Non repeatable part starts from here

  const isCheckboxFocused = useRef(false); //this is used to prevent blur count to be increased in case we  click on different radio options for the same question

  return (
    <Stack
      onBlur={() => {
        setTimeout(() => {
          if (!isCheckboxFocused.current) {
            handleBlur();
          }
        },100)
      }}
      onFocus={() => (isCheckboxFocused.current = true)}
    >
      {formData[data.element.id] ? (
        <CheckboxGroup
          name={data.element.id}
          value={formData[data.element.id].attachments}
          onChange={(val) => {
            handleFormDataChange(val, "", data.element.id, "NA");
            handleValidation(data.element.id)
          }}
        >
          {data &&
          data.element &&
          data.element.properties &&
          data.element.properties.options ? (
            data.element.properties.options.map((option) => {
              const capitalizedOption = capitalizeStringUpdated(option.label);
              return (
                <Box
                  style={{
                    border: "1px solid #EDF2F7",
                    borderRadius: "8px",
                    padding: "12px",
                  }}
                  key={option.value}
                  onFocus={() => (isCheckboxFocused.current = true)}
                  onBlur={() => (isCheckboxFocused.current = false)}
                >
                  <Checkbox
                    size="md"
                    colorScheme="login"
                    value={option.value}
                  >
                    {capitalizedOption}
                  </Checkbox>
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </CheckboxGroup>
      ) : (
        <></>
      )}
    </Stack>
  );
}

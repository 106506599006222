// Chakra imports
import { Box, ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import { useSidebar } from "../context/SidebarContext";
import { userStorage } from "utils/helper";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import { Loader } from "components/Loader/Loader";
import { SocketProvider } from "../context/SocketContext";
import ReportNotifications from "../views/shared/ReportNotifications";

export default function Dashboard(props) {
  const { ...rest } = props;
  const { sidebarOpen } = useSidebar();
  const history = useHistory();

  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  let loginObject = userStorage.get();

  useEffect(() => {
    let sasToken = JSON.parse(localStorage.getItem("inspectionDashboard"));

    if (sasToken) {
      const now = new Date();
      const currentTime = now.setTime(now.getTime() + 0);
      const isTokenExpire = sasToken.expiry < currentTime;
      if (isTokenExpire) {
        localStorage.removeItem("inspectionDashboard");
        sasToken = null;
        history.push("/auth/signin");
      }
    }
    if (!sasToken) {
      history.push("/auth/signin");
    }
  }, []);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].name === "View Workflow") {
            return "View Workflow";
          }
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const entityType = entity?.ownerEntityType;

  const userRole = entity?.role.name;
  const saveLocal = localStorage.setItem("roleName", JSON.stringify(userRole));

  // const userRole = userStorage.get()?.roles || [];
  const isAuditor = userRole?.includes("auditor");
  const isClient = userRole?.includes("client");
  const isSuperAdmin = userRole?.includes("super-admin");
  const isManager = userRole?.includes("manager");
  const isAdmin = userRole?.includes("admin");
  const isViewer = userRole?.includes("viewer");
  const auditorArr = ["Bank Audit", "Account", "Bank Audit ", "Dashboard", "Inspection Detail"];
  const clientArr = [
    "Dashboard",
    "Inspections",
    "Notifications",
    "Account",
    "Warehouses",
    "Collateral Managers",
  ];
  const managerArr = [...clientArr, "Pending Approvals", "Review Form"];
  const customerAdminArr = [
    ...managerArr,
    "Clients",
    "User",
    "Roles & Permissions",
    "Workflows",
    "Jobs",
  ];
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      const isAdminOrManager = isAdmin || isManager;
      const isClientViewer = isViewer && entityType === "client";
      const isCustomerAdmin = isAdminOrManager && entityType === "customer";
      const isBankAuditOrAgency =
        route.name === "Bank Audit" ||
        route.name === "Agency" ||
        route.name.includes("role");
      const isClientViewerRoutes =
        route.name === "Dashboard" ||
        route.name === "Inspections" ||
        route.name === "Warehouses" ||
        route.name === "Collateral Managers" ||
        route.name === "Account";

      const shouldRenderRoute =
        route.layout !== "/admin" ||
        (isAuditor && auditorArr.includes(route.name)) ||
        (isClient && clientArr.includes(route.name)) ||
        (isSuperAdmin && route.name !== "Bank Audit") ||
        (isManager && managerArr.includes(route.name)) ||
        (isCustomerAdmin && !isBankAuditOrAgency) ||
        (isClientViewer && isClientViewerRoutes);

      return shouldRenderRoute ? (
        <Route
          path={route.layout + route.path}
          component={route.component}
          key={key}
        />
      ) : null;
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      {/* <SocketProvider> */}
        {/* <ReportNotifications /> */}
        {/* { loginObject?.role  */}
        {/* {userStorage.get()?.id === "fae0fcc1-3935-4ffa-98b6-1ad63bca8819" ? (
        ""
      ) : ( */}
        <Sidebar
          // brandText={getActiveRoute(routes)}

          display="none"
          {...rest}
        />
        {/* )} */}
        <MainPanel
          className="main-panel"
          w={{
            base: "100%",
            xl:
              // loginObject?.role
              sidebarOpen ? "calc(100% - 265px)" : "93%",
          }}
        >
          <Portal>
            <AdminNavbar
              onOpen={onOpen}
              brandText={getActiveRoute(routes)}
              routeInfo={routes}
              // secondary={getActiveNavbar(routes)}
              fixed={fixed}
              {...rest}
            />
          </Portal>
          {/* {getRoute() ? ( */}
          <PanelContent>
            <Box p={{ sm: "16px", md: "20px" }}>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes)}
                  {userRole ? (
                    <Redirect
                      from={`*`}
                      to={
                        
                          "/admin/dashboard"
                      }
                    />
                  ) : (
                    <Loader size="40px" position="center" margin="75px 0 0 0" />
                  )}
                </Switch>
              </PanelContainer>
            </Box>
          </PanelContent>
          {/* ) : null} */}
          {/* <Footer /> */}
          <Portal>
            <FixedPlugin
              // secondary={getActiveNavbar(routes)}
              fixed={fixed}
              onOpen={onOpen}
            />
          </Portal>
          <Configurator
            // secondary={getActiveNavbar(routes)}
            isOpen={isOpen}
            onClose={onClose}
            isChecked={fixed}
            onSwitch={(value) => {
              setFixed(value);
            }}
            onOpaque={() => setSidebarVariant("opaque")}
            onTransparent={() => setSidebarVariant("transparent")}
          />
        </MainPanel>
      {/* </SocketProvider> */}
    </ChakraProvider>
  );
}

import React, { useEffect, useState } from "react";
import { format, formatISO, parseISO, differenceInDays } from "date-fns";
import { Box, Flex, Text, Image, Link, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, Button, useDisclosure } from "@chakra-ui/react";
import DetailInspectionCard from "./DetailInspectionCard";
import avatarDefault from "../../../../../assets/svg/avatarDefault.svg";
import location from "../../../../../assets/svg/location1.svg";
import Critical from "../../../../../assets/svg/Critical.svg";
import Low from "../../../../../assets/svg/Low.svg";
import High from "../../../../../assets/svg/High.svg";
import Medium from "../../../../../assets/svg/Medium.svg";

const Card = ({ data }) => {
    const { isOpen, onOpen, onClose } = useDisclosure({
        isOpen: isDrawerOpen,
        onClose: () => {
          setIsDrawerOpen(false);
          // Perform any additional cleanup or actions upon closing the drawer
        },
      });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const isoDate = formatISO(new Date(), { representation: "complete" });
    let priorityImg;
    let dayDiff = differenceInDays(parseISO(data.dueDate), parseISO(isoDate));
    switch (data.priority) {
        case "high":
            priorityImg = High;
            break;
        case "critical":
            priorityImg = Critical;
            break;
        case "medium":
            priorityImg = Medium;
            break;
        case "low":
            priorityImg = Low;
            break;
        default:
            priorityImg = Medium;
    }

 const handleClose =()=>{
    onClose();
 }

    return (
        <Box onClick={onOpen} boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)" borderRadius="12px" bg="linear-gradient(97.56deg, #fff, #f6fff9)" border="1px solid #edf2f7" boxSizing="border-box" display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start" padding="16px 16px" gap="12px" lineHeight="normal" letterSpacing="normal" textAlign="left" fontSize="12px" color="#4a5568" width="100%" fontFamily="Roboto">
            <Drawer placement="bottom" onClose={handleClose} isOpen={isOpen}>
                <DrawerOverlay>
                    <DrawerContent style={{ minHeight: "70vh"}} borderRadius={"28px 28px 0px 0px"}>
                        
                        <DrawerBody><DetailInspectionCard assignedDate={format(parseISO(data.creationDt), "EEEE, dd MMMM yyyy")}  dueDate={format(parseISO(data.dueDate), "EEEE, dd MMMM yyyy")} dayLeft={dayDiff} inspectionData = {data}/></DrawerBody>
                        
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
            <Flex alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="12px" textAlign="right" >
                <Box width="40px" height="40px" borderRadius="4px" overflow="hidden" flexShrink={0} backgroundColor="#f7f9fb" p={"1px"}>
                    <Image width="100%" height="100%" objectFit="cover" alt="" src={data.avatar || avatarDefault} />
                </Box>

                <Flex flex={1} alignItems="flex-start" justifyContent="flex-start" gap="0px">
                    <Flex flex={1} flexDirection="column" alignItems="flex-start" justifyContent="flex-start" textAlign="left" fontSize="16px" color="#2d3748" w={"10px"}>
                        <Link textDecoration="none" alignSelf="stretch" letterSpacing="0.15px" lineHeight="24px" fontWeight="500" color="inherit" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={"16px"} w={["100%", "200px", "100px", "350px"]}>
                            {data.clientOrgName}
                        </Link>
                        <Text alignSelf="stretch" fontSize="12px" lineHeight="16px" color="#718096" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {data.category.type || "NA"}
                        </Text>
                    </Flex>
                    {data.status == "todo" ? (
                        <Flex alignItems="center" justifyContent="flex-start" gap="6px" textAlign="left" fontFamily="Inter">
                            <Image height="18px" width="18px" overflow="hidden" flexShrink={0} objectFit="contain" minHeight="18px" loading="lazy" alt=""  src={priorityImg} />
                            <Link textDecoration="none" lineHeight="16px" color="inherit" display="inline-block" minWidth="27px" fontSize={"12px"}>
                                {data.priority}
                            </Link>
                        </Flex>
                    ) : null}
                    {/* <Box height="30px" width="95px" display="none" flexDirection="column" alignItems="flex-start" justifyContent="flex-start" gap="4px">
                        <Flex alignSelf="stretch" flex={1} alignItems="flex-start" justifyContent="space-between" gap="20px">
                            <Text alignSelf="stretch" letterSpacing="0.25px" lineHeight="20px">
                                {data.status}
                            </Text>
                            <Text alignSelf="stretch" letterSpacing="0.25px" lineHeight="20px" fontWeight="500">
                                10%
                            </Text>
                        </Flex>
                        <Image alignSelf="stretch" height="6px" maxWidth="100%" overflow="hidden" flexShrink={0} alt="" src="./public/bar.svg" />
                    </Box>
                    <Box alignSelf="stretch" display="none" flexDirection="column" alignItems="flex-end" justifyContent="space-between">
                        <Flex width="89px" flex={1} alignItems="center" justifyContent="flex-end" padding="0px 0px 0px 22px" boxSizing="border-box" gap="8px">
                            <Image height="18px" width="18px" overflow="hidden" flexShrink={0} alt="" src="./public/smile.svg" />
                            <Text height="20px" flex={1} letterSpacing="0.25px" lineHeight="20px" fontWeight="500" display="inline-block">
                                90.33%
                            </Text>
                        </Flex>
                        <Text lineHeight="16px" color="#718096" textAlign="center">
                            Inspection score
                        </Text>
                    </Box> */}
                </Flex>
            </Flex>

            <Flex alignSelf="stretch" alignItems="flex-end" justifyContent="flex-start" gap="16px">
                <Flex flex={1} flexDirection="column" alignItems="flex-start" justifyContent="flex-start" gap="2px" w={["80px", "100px"]}>
                    <Text alignSelf="stretch" letterSpacing="0.25px" lineHeight="20px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize="12px">
                        {data.status == "done" || data.status == "review-inprogress" || data.status == "approved" ? "completed" : "Due date"}
                    </Text>
                    <Text alignSelf="stretch" fontSize="14px" letterSpacing="0.25px" lineHeight="20px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {format(parseISO(data.dueDate), "EEEE, dd MMMM yyyy")}
                    </Text>
                </Flex>
                <Flex flexDirection="column" alignItems="flex-end" justifyContent="flex-end" gap="6px" textAlign="right" fontSize="14px">
                    <Flex alignItems="center" justifyContent="flex-start" gap="4px">
                        <Link textDecoration="none" letterSpacing="0.25px" lineHeight="20px" color="inherit" display="inline-block" minWidth="44px">
                            <Flex direction={"row"} gap={1}>
                                <Image src={location}></Image>
                                <Text fontSize="14px"> {data.origin.city}</Text>
                            </Flex>
                        </Link>
                    </Flex>
                    <Link textDecoration="none" fontSize="12px" lineHeight="16px" color="inherit" display="inline-block" minWidth="56px">
                        {data.status === "todo" || data.status === "inprogress" ? dayDiff < 0 ? <Text color={"red"}>overdue</Text> : dayDiff == 0 ? <Text color={"#dbdb25"}>today</Text> : <Text color={"green"}> {dayDiff} days left</Text> : null}
                    </Link>
                </Flex>
            </Flex>
            {/* <Box alignSelf="stretch" display="none" flexDirection="column" alignItems="flex-start" justifyContent="flex-start" padding="0px 0px 4px" gap="4px">
                <Flex alignSelf="stretch" alignItems="flex-start" justifyContent="space-between" gap="20px">
                    <Text letterSpacing="0.25px" lineHeight="20px">
                        Progress
                    </Text>
                    <Text height="20px" letterSpacing="0.25px" lineHeight="20px" fontWeight="500" display="inline-block">
                        75%
                    </Text>
                </Flex>
                <Image alignSelf="stretch" height="6px" maxWidth="100%" overflow="hidden" flexShrink={0} alt="" src="./public/bar-1.svg" />
            </Box> */}
        </Box>
    );
};

export default Card;

import React, { useEffect, useState } from "react";
import { DatePicker } from "@agnext/reactlib";
import { useMediaQuery, HStack, IconButton, Box } from "@chakra-ui/react";
import _ from "lodash";
import { Button } from "@agnext/reactlib";
import { AddIcon } from "@chakra-ui/icons";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { endOfDay } from "date-fns";
import { checkBorderForValidRepEle } from "views/Workflow/helper/helper";

const getEodDate = () => {
  const todayDate = new Date();
  return endOfDay(todayDate);
};
const getEodDateInISo = () => {
  return getEodDate().toISOString();
};

export default function DateTimePicker({
  data,
  formData,
  handleFormDataChange,
  repeatable,
  index,
  handleBlur,
  formErrorData={},
  handleValidation

}) {
  if (data.element.repeatable?.isRepeatable || repeatable?.isRepeatable) {

    let minEntries, maxEntries, unlimitedEntries;
    if (data.element.repeatable?.isRepeatable) {
      minEntries = data.element.repeatable?.minEntries;
      maxEntries = data.element.repeatable?.maxEntries;
      unlimitedEntries = data.element.repeatable?.unlimitedEntries;
    }

    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: getEodDateInISo(),
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: getEodDateInISo(),
      });
    }
    const [values, setValues] = useState(
      formData[data.element.id]?.attachments?.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => ({
              order: index + 1,
              value,
            })),
            {
              order:
                formData[data.element.id]?.attachments[
                  formData[data.element.id]?.attachments?.length - 1
                ]?.order + 1,
              value: getEodDateInISo(),
            },
          ]
        : initialValues
    );
    const handleAddMore = () => {
      if (
        !values[values.length - 1].value ||
        (values.length >= maxEntries && !unlimitedEntries)
      )
        return;
      setValues((prevValues) => [
        ...prevValues,
        {
          order: prevValues[prevValues.length - 1].order + 1,
          value: getEodDateInISo(),
        },
      ]);
    };

    const handleDelete = (order) => {
      if (values.length < 2 || values.length <= minEntries) return;
      const newVals = values.filter((value) => value.order !== order);
      const updatedVals = newVals.map((value, index) => ({
        ...value,
        order: index + 1,
      }));
      setValues(updatedVals);
      handleFormDataChange(data.element.id, "repeatable-delete", order - 1);
    };

    const elementData = formData[data.element.id]?.attachments;
    useEffect(() => {
      if (elementData.length) {
        setValues(
          elementData.map((value, index) => ({
            order: index + 1,
            value,
          }))
        );
      } else {
        values.forEach((value) => {
          handleFormDataChange(
            { target: { name: data.element.id, value: value.value } },
            "repeatable",
            value.order
          );
        });
      }
    }, []);

    if (repeatable?.isRepeatable) {
      return (
        <HStack w="100%" mb="8px">
          <DatePickerRepeatable
            data={data}
            formData={formData}
            handleFormDataChange={handleFormDataChange}
            index={index}
            value={values[index]}
            handleBlur={handleBlur}
            formErrorData={formErrorData}
            handleValidation={handleValidation}
          />
        </HStack>
      );
    }
    return (
      <>
        {values &&
          values.map((value) => (
            <HStack w="100%" mb="8px">
              <DatePickerRepeatable
                data={data}
                formData={formData}
                handleFormDataChange={handleFormDataChange}
                index={value.order - 1}
                value={value}
                handleBlur={handleBlur}
                formErrorData={formErrorData}
                handleValidation={handleValidation}
              />
              <IconButton
                variant="ghost"
                borderRadius="8px"
                isDisabled={values.length < 2 || values.length <= minEntries}
                sx={{
                  ".icon": {
                    color: "red.500",
                  },
                }}
                icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                onClick={() => {
                  handleDelete(value.order);
                  handleBlur()
                }}
              />
            </HStack>
          ))}
        <Button
          size="sm"
          borderRadius="8px"
          onClick={handleAddMore}
          leftIcon={<AddIcon />}
          label="Add More"
          isDisabled={
            !values[values.length - 1].value ||
            (values.length >= maxEntries && !unlimitedEntries)
          }
        />
      </>
    );
  }

  const [date, setDate] = useState(() => {
    const initialValue = formData[data.element.id]?.value;

    if (initialValue) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        return initialValue;
      } else if (typeof initialValue === "string") {
        return new Date(initialValue);
      }
    }
    return getEodDate();
  });

  useEffect(() => {
    const initialValue = formData[data.element.id]?.value;
    if (typeof date === "object" && date instanceof Date) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        if (date.toISOString() !== initialValue.toISOString()) {
          setDate(initialValue);
        }
      } else if (initialValue == "" && initialValue !== date.toISOString()) {
        setDate(getEodDate());
      } else if (typeof initialValue === "string") {
        if (date.toISOString() !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    } else if (typeof date === "string") {
      if (typeof initialValue === "string") {
        if (date !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    }
  }, [formData[data.element.id]?.value]);

  const [isMobileScreen] = useMediaQuery("(max-width: 500px)");
  useEffect(() => {
    if (formData[data.element.id]?.value != undefined) {
      handleFormDataChange(date, "", data.element.id, "NA");
    }
  }, [data.element.id, date]);

  return (
    <>
      <DatePicker
        setDate={(val) => {
          setDate(val);
          handleBlur();
          handleValidation(data.element.id);
        }}
        date={date}
        width={isMobileScreen ? 245 : 330}
        colorSelected="#fff"
        backgroundSelected="#4FD1C5"
      />
    </>
  );
}

export function DatePickerRepeatable({
  data,
  formData,
  handleFormDataChange,
  value,
  index,
  handleBlur,
  formErrorData={},
  handleValidation
}) {
  const [date, setDate] = useState(() => {
    const initialValue = formData[data.element.id]?.attachments[index];
    if (initialValue) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        return initialValue;
      } else if (typeof initialValue === "string") {
        return new Date(initialValue);
      }
    }
    return getEodDate();
  });
  useEffect(() => {
    const initialValue = formData[data.element.id]?.attachments[index];
    if (typeof date === "object" && date instanceof Date) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        if (
          date.toISOString().split("T")[0] !==
          initialValue.toISOString().split("T")[0]
        ) {
          setDate(new Date(initialValue));
        }
      } else if (!initialValue && initialValue !== date.toISOString()) {
        setDate(new Date(getEodDateInISo()));
      } else if (typeof initialValue === "string") {
        if (date.toISOString() !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    } else if (typeof date === "string") {
      if (typeof initialValue === "string") {
        if (date !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    }
  }, [formData[data.element.id]?.attachments[index]]);

  const [isMobileScreen] = useMediaQuery("(max-width: 500px)");
  useEffect(() => {
    const initialValue = formData[data.element.id]?.attachments[index];
    if (initialValue?.split("T")[0] !== value?.value.split("T")[0]) {
      if (typeof date === "object" && date instanceof Date) {
        if (typeof initialValue === "object" && initialValue instanceof Date) {
          if (date.toISOString() !== initialValue.toISOString()) {
            handleFormDataChange(
              date.toISOString(),
              "repeatableDate",
              data.element.id,
              index
            );
          }
        } else if (
          !initialValue &&
          initialValue?.split("T")[0] !== date.toISOString().split("T")[0]
        ) {
          // setDate(new Date());
          handleFormDataChange(
            date.toISOString(),
            "repeatableDate",
            data.element.id,
            index
          );
        } else if (typeof initialValue === "string") {
          if (
            date.toISOString().split("T")[0] !== initialValue?.split("T")[0]
          ) {
            // setDate(new Date(initialValue));
            handleFormDataChange(
              date.toISOString(),
              "repeatableDate",
              data.element.id,
              index
            );
          }
        }
      } else if (typeof date === "string") {
        if (typeof initialValue === "string") {
          if (date.split("T")[0] !== initialValue?.split("T")[0]) {
            handleFormDataChange(
              date.toISOString(),
              "repeatableDate",
              data.element.id,
              index
            );
          }
        }
      }
    } else if (
      !initialValue ||
      date.toISOString().split("T")[0] !== initialValue?.split("T")[0]
    ) {
      handleFormDataChange(
        date.toISOString(),
        "repeatableDate",
        data.element.id,
        index
      );
    }
  }, [data.element.id, date.toISOString()]);

  return (
    <Box
    border= {checkBorderForValidRepEle(index, formErrorData, data)}
    borderRadius="6px"
    >
      <DatePicker
        setDate={(val) => {
          setDate(val);
          handleBlur();
          handleValidation(data.element.id, index);
        }}
        date={date}
        width={isMobileScreen ? 245 : 330}
        colorSelected="#fff"
        backgroundSelected="#4FD1C5"
      />
    </Box>
  );
}
import { call, put, takeLatest, takeEvery, delay } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "../../../../services/index";
import * as API1 from "../../../../services/apis";

export function* handleFetchReviewData({ payload }) {
  try {
    const res = yield call(API.getData, payload);
    const { data } = res;
    yield put(actions.fetchReviewDataSuccess(data.payload));
    payload.onSuccess({ data: data.payload, components: payload.components });
  } catch (err) {
    //console.log(err);
    payload.onFailure();
    yield put(actions.fetchReviewDataFailure(err?.response?.data));
  }
}
export function* handleFetchFormData({ payload }) {
  try {
    const res = yield call(API.fetchFormData, payload);
    const { data } = res;
    yield put(actions.fetchFormDataSuccess(data.payload));
    payload.onSuccess(data.payload.components);
  } catch (err) {
    payload.onFailure();
    yield put(actions.fetchFormDataFailure(err?.response?.data));
  }
}

export function* handlePostWorkflowData({ payload }) {
  try {
    const res = yield call(API.postFormData, payload.data);
    // console.log({ res });
    // let data = JSON.parse(res.data);
    // yield put(actions.postWorkflowDataSuccess(data));
  } catch (err) {
    //console.log({ err });
    yield put(actions.postWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleFetchSingleTask({ payload }) {
  try {
    const res = yield call(API.getSingleTask, payload);
    let data = res.data;
    yield put(actions.fetchSingleTaskSuccess(data.payload));
    if (data.payload.status === "inprogress") {
      payload.onSuccess();
    } else {
      payload.onFailure();
    }
  } catch (err) {
    console.log({ err });
    payload.onFailure();
    yield put(actions.fetchSingleTaskFailure(err?.response?.data));
  }
}
export function* handlePostFileData({ payload }) {
  try {
    const sasURLData = yield call(API.fetchSasurl, payload);
    const sasURL = sasURLData && sasURLData.data.payload.url;
    payload.onSuccess();
    const res = yield call(API.uploadFile, { ...payload, sasURL });
    yield put(actions.postFileDataSuccess(payload));
  } catch (err) {
    payload.onFailure();
    yield put(
      actions.postFileDataFailure({ err: err?.response?.data, payload })
    );
  }
}

export function* handleLocation({payload}){
  try{
     let c =yield call(API1.locationTrack, payload)
     console.log(c)
    //  yield put(actions.fetchLocationSuccess(false))
  }
  catch(err) {
    console.log(err)
  }
}
export function* handleCreatePreview({payload}) {
    try{
      const previewData = yield call(API1.previewGenerator, payload)
    }
    catch(err) {
    console.log(err)
    }
}

/***
 * Root saga manages watcher lifecycle
 ***/

export function* formDataSaga() {
  // yield takeLatest(actions.postWorkflowData.type, handlePostWorkflowData);
  yield takeLatest(actions.fetchFormData.type, handleFetchFormData);
  yield takeLatest(actions.fetchReviewData.type, handleFetchReviewData);
  yield takeLatest(actions.fetchSingleTask.type, handleFetchSingleTask);
  yield takeLatest(actions.fetchLocation.type, handleLocation);
  yield takeEvery(actions.postFileData.type, handlePostFileData);
  yield takeLatest(actions.createPreview.type, handleCreatePreview);
}
